* {
  box-sizing: border-box;
}

body {
  background-color: #121212;
  max-height: 90vh;
  width: 100%;
  margin: 0;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.container {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-btw {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.skin-preview {
  border: 1px dashed #424242;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  position: relative;
  cursor: grab;
}

.scroll {
  overflow-y: scroll;
  max-height: 600px;
}

.scroll-x {
  overflow-x: scroll;
}

.no-margin {
  margin: 0;
}

.float-button {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
}

.text-left {
  text-align: left;
}

.align-baseline {
  align-items: baseline;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Estilo de fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Estilo de la manija de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151);
  border-radius: 12px;
}

/* Estilo del borde de la barra de desplazamiento */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(116, 116, 116);
}

.red {
  color: #cc0000;
}

.fixed-center {
  position: fixed;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
}

.image-button {
  height: 100px;
  aspect-ratio: 16/9;
  background-color: transparent;
  border: 0;
  transition: transform 150ms ease;
  padding: 0;
  cursor: pointer;
}

.image-button img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.image-button:hover {
  transform: scale(0.95);
}

.carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-image: fill 0
    linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 10%
    );
  z-index: 10;
  width: 100%;
  height: calc(100% - 6px);
}
