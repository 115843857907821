.cursor-zoom-in {
  cursor: zoom-in;
}

.block-texture {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 64px;
  image-rendering: pixelated;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
}

.image-preview-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 110;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  background-color: rgba(133, 133, 133, 0.5);
  backdrop-filter: blur(5px);
  animation: show-modal 500ms ease-in-out;
}

.image-preview-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  cursor: zoom-out;
}

.image-preview-wrapper img {
  width: 600px;
  image-rendering: pixelated;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.close {
  animation: hide-modal 500ms ease-in-out forwards;
}

.image-preview-buttons-wrapper {
  width: 600px;
  display: flex;
  justify-content: right;
}

.image-preview-buttons {
  box-sizing: border-box;
  display: flex;
  justify-content: right;
  gap: 8px;
  margin-bottom: 8px;
  border-radius: 50px;
  font-size: 1.5rem;
}

.image-preview-grid {
  display: grid;
  grid-template-columns: repeat(3, 200px);
  grid-template-rows: repeat(3, 200px);
}

.image-preview-grid img {
  width: 200px;
}

@keyframes show-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
